import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import categoriesReducer from './features/categoriesSlice';
import favoritesReducer from './features/favoritesSlice';
import servicesReducer from './features/servicesSlice';
import filterReducer from './features/filterSlice';

export const stStore = configureStore({
  reducer: {
    categories: categoriesReducer,
    favorites: favoritesReducer,
    services: servicesReducer,
    filter: filterReducer,
  },
});

export type STDispatch = typeof stStore.dispatch;

export const useSTDispatch = () => useDispatch<typeof stStore.dispatch>();

export type STState = ReturnType<typeof stStore.getState>;
