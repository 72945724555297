import { Col, Row } from 'antd';
import MailIcon from '../../assets/icons/mail.svg';
import { useForm, ValidationError } from '@formspree/react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Contact.css';
import { useEffect } from 'react';

function Contact() {
  const [state, handleSubmit] = useForm('mgegboqv');
  const successId = 'successId-yes';
  const errorId = 'errorId-yes';

  useEffect(() => {
    if (state.errors) {
      toast.error('Error during form submission', {
        toastId: errorId,
      });
    } else if (state.succeeded) {
      toast.success('Form submitted succesfully', {
        toastId: successId,
      });
      for (const form of document.getElementsByTagName('form')) {
        form.reset();
      }
    }
  }, [state.errors, state.succeeded]);

  return (
    <div className="Contact">
      <ToastContainer autoClose={3000} />
      <Row gutter={24}>
        <Col className="gutter-row" lg={10}>
          <div>
            <div className="icon">
              <img src={MailIcon} alt="" />
            </div>
            <h1 className="header">Have questions or ready to explore Status Trust?</h1>
            <p className="text">
              Reach out to us for personalized solutions, expert guidance, and unparalleled support.
            </p>
          </div>
        </Col>
        <Col className="gutter-row" lg={14}>
          <form onSubmit={handleSubmit} className="contact-form">
            <Row gutter={[24, 12]}>
              <Col className="gutter-row" xs={24} lg={12}>
                <input type="text" name="name" id="name" placeholder="Name" />
                <ValidationError className="input-errorMsg" prefix="Name" field="name" errors={state.errors} />
              </Col>
              <Col className="gutter-row" xs={24} lg={12}>
                <input type="email" name="email" id="email" placeholder="Email" />
                <ValidationError className="input-errorMsg" prefix="Email" field="email" errors={state.errors} />
              </Col>
              <Col className="gutter-row" xs={24}>
                <textarea name="message" id="message" placeholder="Message" />
                <ValidationError className="input-errorMsg" prefix="Message" field="message" errors={state.errors} />
              </Col>
              <Col xs={24}>
                <button type="submit" disabled={state.submitting}>
                  Send Message
                </button>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </div>
  );
}

export default Contact;
