import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import localStorageHelper from '../utils/localStorageHelper';
import { FavoriteItem } from '../models';

const FAV_KEY = 'favorite';

export interface FavoritesState {
  values: FavoriteItem[];
}

const initialState: FavoritesState = {
  values: localStorageHelper.getArray<FavoriteItem>(FAV_KEY).filter(f => f.category && f.id),
};

export const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    toggleFavorite: (state, action: PayloadAction<FavoriteItem>) => {
      if (state.values.find(f => f.id === action.payload.id)) {
        state.values = state.values.filter(f => f.id !== action.payload.id);
      } else {
        state.values.push(action.payload);
      }

      localStorageHelper.saveItemsToArray(state.values, FAV_KEY);
    },
  },
});

export const { toggleFavorite } = favoritesSlice.actions;

export default favoritesSlice.reducer;

export const selectFavorites = (state: FavoritesState) => state.values;
