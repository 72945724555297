import { useReducer } from 'react';
import { PublicServiceChart } from '../models';
import dayjs from 'dayjs';

interface ActionType {
  type: 'ERROR' | 'LOADING' | 'LOADED';
  payload?: PublicServiceChart;
  error?: Error;
}

interface ChartState {
  data: PublicServiceChart;
  loading: 'idle' | 'loading' | 'succeeded' | 'failed';
  error?: string;
}

const initialState: ChartState = {
  error: undefined,
  loading: 'idle',
  data: {
    data: [],
    summary: {
      reportPercentage: 0,
      totalCheckCount: 0,
      totalFailureCount: 0,
      totalSuccessCount: 0,
      totalIncidentCount: 0,
    },
  },
};

const chartReducer = (state: ChartState, action: ActionType): ChartState => {
  switch (action.type) {
    case 'ERROR':
      return {
        loading: 'failed',
        error: action.error?.message || 'HTTP error',
        data: initialState.data,
      };
    case 'LOADED':
      return {
        data: {
          ...action.payload!,
          data: action.payload!.data.sort((a, b) => (dayjs(a.date).isBefore(b.date) ? -1 : 1)),
        },
        loading: 'succeeded',
      };
    case 'LOADING':
      return {
        ...initialState,
        loading: 'loading',
      };
    default:
      throw new Error();
  }
};

export const useChart = (): [state: ChartState, dispatch: React.Dispatch<ActionType>] => {
  const [state, dispatch] = useReducer(chartReducer, {
    ...initialState,
  });

  return [state, dispatch];
};
