import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ChartOptions,
} from 'chart.js';
import dayjs from 'dayjs';

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend);

const chartConfig = (max: number, totalCheckCounts: number[]): ChartOptions<'bar'> => {
  return {
    aspectRatio: 5,
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
          width: 0,
        },
        ticks: {
          color: '#6d6b76',
          font: {
            size: 10,
            weight: 600,
          },
        },
      },
      y: {
        max,
        ticks: {
          stepSize: 16,
          color: '#6d6b76',
          padding: 10,
          font: {
            size: 12,
            weight: 600,
          },
        },
        border: {
          dash: [20, 5],
          display: false,
        },
        grid: {
          color: '#cac7d533',
          tickLength: 0,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: '#7752fe',
        displayColors: false,
        padding: {
          bottom: 3,
          top: 3,
          left: 14,
          right: 14,
        },
        yAlign: 'bottom',
        bodyFont: {
          size: 15,
          weight: 600,
        },
        callbacks: {
          label: v => {
            const failedCount = totalCheckCounts[v.parsed.x] - +v.formattedValue;
            return failedCount
              ? `${failedCount} of ${v.formattedValue} failed`
              : `${v.formattedValue} of ${v.formattedValue} succeeded`;
          },
          title: () => '',
        },
      },
    },
  };
};

interface STBarChartProps {
  data: { date: string; total: number; success: number; incident: number }[];
}

export function STBarChart({ data }: STBarChartProps) {
  const labels = data.map(d => dayjs(d.date).format('MMM DD').toUpperCase());
  const colors = data.map(d => {
    if (d.success > 12) {
      return '#50c878';
    }
    if (d.incident > 12) {
      return '#fabc3c';
    }
    if (d.total - d.success - d.incident > 12) {
      return '#ff5733';
    }

    return '#cac7d5';
  });

  return (
    <Bar
      data={{
        labels,
        datasets: [
          {
            data: data.map(d => d.success),
            backgroundColor: colors,
            borderColor: colors,
            borderWidth: 1,
            borderRadius: 3,
          },
        ],
      }}
      options={chartConfig(
        Math.max(...data.map(d => d.total)),
        data.map(d => d.total)
      )}
    />
  );
}
