const APP_KEY = 'STATUS_TRUST.';

const localStorageHelper = {
  load<T>(key: string): T | undefined {
    const stored = localStorage.getItem(`${APP_KEY}${key}`);
    if (!stored) {
      return;
    }

    try {
      const res = JSON.parse(stored);
      return res;
    } catch (e) {
      return;
    }
  },

  save<T>(key: string, value: T) {
    localStorage.setItem(`${APP_KEY}${key}`, JSON.stringify(value));
  },

  getArray<T>(storageKey: string): T[] {
    return this.load<T[]>(storageKey) || [];
  },

  saveItemsToArray<T>(items: T[], storageKey: string) {
    this.save(storageKey, items);
  },
};

export default localStorageHelper;
