import { PublicService, getHealthStatusColor, getHealthStatusText } from '../../models';
import './StatusCard.css';
import { ReactComponent as STStatusSvg } from '../../assets/icons/status.svg';
import { ReactComponent as STChartSvg } from '../../assets/icons/chart.svg';
import Icon from '@ant-design/icons';
import { Popover } from 'antd';
import { StatusCardHeader } from '.';
import { toStartUpperCase } from '../../utils';

interface StatusCardProps {
  service: PublicService;
  isFavorite: boolean;
  groupTitle?: string;
  onClickFavorite: (id: string, category: string) => void;
  onClickServiceStatus: (id: string) => void;
}

export function StatusCard({
  service,
  isFavorite,
  groupTitle,
  onClickFavorite,
  onClickServiceStatus,
}: StatusCardProps) {
  const healthInfoPopover = service.healthInfo && service.healthStatus === 'Incident' && (
    <div>
      <p>{service.healthInfo}</p>
    </div>
  );

  return (
    <div className="StatusCard__wrapper">
      {groupTitle && <h2 className="group-title">{toStartUpperCase(groupTitle)}</h2>}
      <div className="status-card half-px-border">
        <StatusCardHeader
          service={service}
          isFavorite={isFavorite}
          onClickFavorite={onClickFavorite}
          onClickTitle={() => onClickServiceStatus(service.serviceId)}
        />
        <div className="footer-title">Status</div>
        <div className="flex-row footer-content">
          <div>
            <Popover content={healthInfoPopover}>
              <Icon
                component={STStatusSvg}
                style={{ color: getHealthStatusColor(service.healthStatus), fontSize: '32px' }}
              />
            </Popover>
          </div>
          <div>{getHealthStatusText(service.healthStatus)}</div>
          <Icon
            onClick={() => onClickServiceStatus(service.serviceId)}
            component={STChartSvg}
            style={{ color: '#cac7d5', fontSize: '24px' }}
          />
        </div>
      </div>
    </div>
  );
}
