import PerformanceImage from '../../assets/website/performance.svg';
import MonitoringImage from '../../assets/website/monitor.svg';
import DomainImage from '../../assets/website/liveupdates.svg';
import LockImage from '../../assets/website/lock.svg';
import PingsImage from '../../assets/website/pings.svg';
import ChartIcon from '../../assets/icons/performance.svg';
import ServiceIcon from '../../assets/icons/service.png';
import DomainIcon from '../../assets/icons/world.svg';
import LockIcon from '../../assets/icons/lock.svg';
import PingsIcon from '../../assets/icons/pings.svg';
import { Col, Row } from 'antd';
import './Cards.css';

function Cards() {
  const card = [
    {
      icon: ChartIcon,
      header: 'Comprehensive Performance Insights.',
      description: `Gain deep insights into your brand's performance, enabling you to make data-driven decisions that drive
            success.`,
      image: PerformanceImage,
    },
    {
      icon: ServiceIcon,
      header: 'Real-time Service Monitoring.',
      description: `Stay in control with our advanced monitoring tools, ensuring every aspect of your services is on track.`,
      image: MonitoringImage,
    },
    {
      icon: DomainIcon,
      header: 'Client Custom Domain and Customer Dashboard.',
      description: `Tailor your experience, and elevate your brand with personalized solutions. Enhancing brand identity with custom domains empowering customers with a comprehensive, publishable dashboard for services insights.`,
      image: DomainImage,
    },
    {
      icon: PingsIcon,
      header: 'Pings. Get Reports on Other Services.',
      description: '',
      image: PingsImage,
    },
    {
      icon: LockIcon,
      header: `Virtual Private Cloud & Private Network Support.`,
      description: '',
      image: LockImage,
    },
  ];

  return (
    <div className="about-cards">
      <h1 className="about-cards-header">
        Best features provided by <br /> Status Trust
      </h1>

      <Row gutter={[24, 24]}>
        {card.map((x, key) => (
          <Col className="gutter-row" xs={24} lg={key === Math.floor(card.length / 2) ? 24 : 12} key={key}>
            <div
              className={`card ${key < 2 && 'start-cards'} ${key === Math.floor(card.length / 2) && 'middle-card'} ${
                key > 2 && 'end-cards'
              } ${key === 1 && 'second-card'}`}
            >
              <div className="content">
                <div className="icon">
                  <img src={x.icon} alt=" " />
                </div>
                <h3 className="content-header">{x.header}</h3>
                <p className="text">{x.description}</p>
              </div>
              <div className="image">
                <img src={x.image} alt=" " />
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default Cards;
