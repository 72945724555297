import { PublicCategory } from '../models';
import { api } from './configs/axiosConfigs';

export const PublicCategoryAPI = {
  getCategories: async function (): Promise<PublicCategory[]> {
    const response = await api.request<string[]>({
      url: '/public/categories',
      method: 'GET',
    });

    return response.data.map((name, index) => {
      return {
        name,
        key: index + 1,
      };
    });
  },
};
