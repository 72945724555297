import logo from '../../assets/logo.svg';
import { Button, Dropdown } from 'antd';
import './Navbar.css';
import { MenuOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-scroll';

export function Navbar() {
  const getTargetUrl = () => {
    if (process.env.REACT_APP_ENV === 'production') {
      return '/coming-soon';
    } else {
      return process.env.REACT_APP_WEB_APP_URL;
    }
  };

  const items = [
    {
      key: '1',
      label: (
        <Link activeClass="active" to="about" spy={true} smooth={true} offset={0} duration={500}>
          About
        </Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link activeClass="active" to="testimonials" spy={true} smooth={true} offset={0} duration={500}>
          Testimonials
        </Link>
      ),
    },
    {
      key: '3',
      label: (
        <Link activeClass="active" to="contact" spy={true} smooth={true} offset={0} duration={500}>
          Contact Us
        </Link>
      ),
    },
    {
      key: '4',
      label: (
        <Link activeClass="active" to="faqs" spy={true} smooth={true} offset={0} duration={500}>
          FAQs
        </Link>
      ),
    },
    {
      key: '5',
      label: (
        <NavLink to="/" target="_blank">
          <Button size="large">Public Status Page</Button>
        </NavLink>
      ),
    },
    {
      key: '6',
      label: (
        <NavLink to={getTargetUrl()!} target="_blank">
          <Button type="primary" size="large">
            Sign In
          </Button>
        </NavLink>
      ),
    },
  ];

  return (
    <nav className="nav">
      <div className="container flex-row plr-page">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <Dropdown className="hoverable nav-desktop-hidden" menu={{ items }} placement="bottomLeft">
          <MenuOutlined style={{ fontSize: '28px', color: '#1e1e1e' }} />
        </Dropdown>
        <div className="nav-links nav-mobile-hidden">
          <NavLink to={'#'}>
            <Link activeClass="active" to="about" spy={true} smooth={true} offset={0} duration={500} className="text">
              About
            </Link>
          </NavLink>
          <NavLink to={'#'}>
            <Link
              activeClass="active"
              to="testimonials"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              className="text"
            >
              Testimonials
            </Link>
          </NavLink>
          <NavLink to={'#'}>
            <Link activeClass="active" to="contact" spy={true} smooth={true} offset={0} duration={500} className="text">
              Contact Us
            </Link>
          </NavLink>
          <NavLink to={'#'}>
            <Link activeClass="active" to="faqs" spy={true} smooth={true} offset={0} duration={500} className="text">
              FAQs
            </Link>{' '}
          </NavLink>
        </div>
        <Button onClick={() => window.open('/', '_blank')} className="nav-mobile-hidden" size="large">
          Public Status Page
        </Button>
        <Button
          onClick={() => window.open(getTargetUrl(), '_blank')}
          className="nav-mobile-hidden"
          type="primary"
          size="large"
        >
          Sign In
        </Button>
      </div>
    </nav>
  );
}
