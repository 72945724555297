import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface FilterState {
  category?: string;
  name?: string;
}

const initialState: FilterState = {
  category: '',
  name: '',
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<FilterState>) => {
      state.category = action.payload.category;
      state.name = action.payload.name;
    },
  },
});

export const { setFilter } = filterSlice.actions;

export default filterSlice.reducer;

export const selectFavorites = (state: FilterState) => state.category;
