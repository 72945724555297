import './STLayout.css';
import { Outlet } from 'react-router-dom';
import { STHeader } from '../components/ui';

export function STLayout() {
  return (
    <>
      <div className="STLayout_background"></div>
      <div className="STLayout_wrapper">
        <STHeader />
        <Outlet />
      </div>
    </>
  );
}
