import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PublicServiceAPI } from '../apis';
import { PublicService, PublicServiceEvent, PublicServiceResponse } from '../models';
import { STState } from '../stStore';

export interface ServicesState {
  values: [category: string, services: PublicServiceResponse][];
  loading: 'idle' | 'loading' | 'succeeded' | 'failed';
  loadingCategory?: string;
}

const initialState: ServicesState = {
  values: [],
  loading: 'idle',
  loadingCategory: 'all',
};

export const initServices = createAsyncThunk(
  'services/init',
  async ({ categories, favorites }: { categories: string[]; favorites: string[] }) => {
    const result: [category: string, services: PublicServiceResponse][] = [];
    const requests = categories.map(category =>
      PublicServiceAPI.getPaginated({
        category,
      })
    );
    const services = await Promise.all([...requests]);

    const favRequests = favorites.map(id => PublicServiceAPI.getById(id).catch(() => undefined));
    const favServices = (await Promise.all([...favRequests])).filter((f): f is PublicService => !!f);

    if (favServices.length > 0) {
      result.push([
        'Favorites',
        {
          data: favServices,
          page: -1,
          pageSize: -1,
          totalCount: -1,
          totalPageCount: -1,
        },
      ]);
    }

    services.forEach((service, index) => {
      result.push([categories[index], service]);
    });

    return result;
  }
);

export const loadMore = createAsyncThunk(
  'services/addToCategory',
  async (
    {
      category,
      filter,
    }: {
      category: string;
      filter?: { category?: string; name?: string };
    },
    { getState }
  ) => {
    const state = getState() as STState;
    const lastResponse = state.services.values.find(([c]) => c === category)![1];
    const nextResponse = await PublicServiceAPI.getPaginated({
      category: filter?.category?.length ? filter.category : filter?.name?.length ? undefined : category,
      name: filter?.name || undefined,
      page: lastResponse.page + 1,
      pageSize: lastResponse.pageSize,
    });

    return nextResponse;
  }
);

export const filterServices = createAsyncThunk(
  'services/filterServices',
  async ({ name, category }: { name?: string; category?: string }) => {
    return await PublicServiceAPI.getPaginated({
      name,
      category,
      page: 0,
      pageSize: 6,
    });
  }
);

export const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    updateService: (state, action: PayloadAction<PublicServiceEvent>) => {
      const relatedCategory = state.values.find(([, services]) =>
        services.data.some(service => service.serviceId === action.payload.serviceId)
      );

      if (!relatedCategory) {
        return;
      }

      const categoryName = relatedCategory[0];
      const prevResponse = relatedCategory[1];

      const nextServices = prevResponse.data.map(service => {
        if (service.serviceId === action.payload.serviceId) {
          return {
            ...service,
            ...action.payload,
          };
        }

        return service;
      });

      const index = state.values.map(([category]) => category).indexOf(categoryName);
      state.values[index] = [
        categoryName,
        {
          ...prevResponse,
          data: nextServices,
        },
      ];
    },
  },
  extraReducers: builder => {
    builder.addCase(initServices.fulfilled, (state, action) => {
      state.values = action.payload;
      state.loading = 'succeeded';
      state.loadingCategory = undefined;
    });
    builder.addCase(initServices.pending, state => {
      state.values = [];
      state.loading = 'loading';
      state.loadingCategory = 'all';
    });
    builder.addCase(initServices.rejected, state => {
      state.values = [];
      state.loading = 'failed';
      state.loadingCategory = undefined;
    });

    builder.addCase(loadMore.fulfilled, (state, action) => {
      const currentResponse = state.values.find(([category]) => category === action.meta.arg.category)![1];
      currentResponse.page = currentResponse.page + 1;
      currentResponse.data = [...currentResponse.data, ...action.payload.data].filter(
        (s, index, arr) => arr.findIndex(t => t.serviceId === s.serviceId) === index
      );

      state.loading = 'succeeded';
      state.loadingCategory = undefined;
    });
    builder.addCase(loadMore.pending, (state, { meta }) => {
      state.loading = 'loading';
      state.loadingCategory = meta.arg.category;
    });
    builder.addCase(loadMore.rejected, state => {
      state.loading = 'failed';
      state.loadingCategory = undefined;
    });

    builder.addCase(filterServices.fulfilled, (state, { meta, payload }) => {
      state.values = [
        [
          [meta.arg.category || '', meta.arg.name ? `"${meta.arg.name}"` : ''].filter(n => n.length > 0).join(' - '),
          payload,
        ],
      ];
      state.loading = 'succeeded';
      state.loadingCategory = undefined;
    });
    builder.addCase(filterServices.pending, state => {
      state.values = [];
      state.loading = 'loading';
      state.loadingCategory = 'all';
    });
    builder.addCase(filterServices.rejected, state => {
      state.values = [];
      state.loading = 'failed';
      state.loadingCategory = undefined;
    });
  },
});

export const { updateService } = servicesSlice.actions;

export default servicesSlice.reducer;

export const selectServices = (state: ServicesState) => {
  return {
    values: state.values,
    loading: state.loading,
  };
};
