import logo from '../../assets/logo.svg';
import { Button, Dropdown } from 'antd';
import './STHeader.css';
import { MenuOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

export function STHeader() {
  const getTargetUrl = () => {
    if (process.env.REACT_APP_ENV === 'production') {
      return '/coming-soon';
    } else {
      return process.env.REACT_APP_WEB_APP_URL;
    }
  };

  const items = [
    {
      key: '1',
      label: (
        <Link to="/about" target="_blank">
          Go to Official Website
        </Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link to={getTargetUrl()!} target="_blank">
          Sign In
        </Link>
      ),
    },
  ];

  return (
    <header className="STHeader__wrapper flex-row plr-page pt-lg">
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <Dropdown className="hoverable desktop-hidden" menu={{ items }} placement="bottomLeft">
        <MenuOutlined style={{ fontSize: '28px', color: '#1e1e1e' }} />
      </Dropdown>
      <Button onClick={() => window.open('/about', '_blank')} className="mobile-hidden" size="large">
        Go to Official Website
      </Button>
      <Button
        onClick={() => window.open(getTargetUrl(), '_blank')}
        className="mobile-hidden"
        type="primary"
        size="large"
      >
        Sign In
      </Button>
    </header>
  );
}
