import { Button } from 'antd';
import { Navbar } from '../components/website/Navbar';
import DasboardImage from '../assets/website/dashboard.svg';
import Cards from '../components/website/Cards';
import Accordian from '../components/website/Accordian';
import Contact from '../components/website/Contact';
// import Carousel from '../components/website/Carousel';
import Footer from '../components/website/Footer';
import { FaArrowRightLong } from 'react-icons/fa6';
import './Website.css';

export function Website() {
  const getTargetUrl = () => {
    if (process.env.REACT_APP_ENV === 'production') {
      return '/coming-soon';
    } else {
      return process.env.REACT_APP_WEB_APP_URL;
    }
  };

  return (
    <div className="Website">
      <Navbar />
      <section className="about">
        <div className="grid">
          <h1 className="header">
            Keep track of <br /> everything.
          </h1>
          <div>
            <p className="text">
              Our mission is to empower businesses like yours by providing cutting-edge solutions to keep track of your
              services and elevate your brand's performance.
            </p>
            <Button onClick={() => window.open(getTargetUrl(), '_blank')} type="primary">
              <p>Get Started</p> <FaArrowRightLong className="website-btnicon" />
            </Button>
          </div>
        </div>
        <img src={DasboardImage} alt=" " className="dashboard-image" />
        <Cards />
      </section>
      {/* <section className="testimonials">
        <Carousel />
      </section> */}
      <section className="faqs">
        <Accordian />
      </section>
      <section className="contact">
        <Contact />
      </section>
      <Footer />
    </div>
  );
}
