import { useReducer } from 'react';
import { PublicServiceHistoryResponse } from '../models';

interface ActionType {
  type: 'ERROR' | 'LOADING' | 'LOADED';
  payload?: PublicServiceHistoryResponse;
  error?: Error;
}

interface historyState {
  data?: PublicServiceHistoryResponse;
  loading: 'idle' | 'loading' | 'succeeded' | 'failed';
  error?: string;
}

const historyReducer = (state: historyState, action: ActionType): historyState => {
  switch (action.type) {
    case 'ERROR':
      return {
        loading: 'failed',
        error: action.error?.message || 'HTTP error',
      };
    case 'LOADED':
      return {
        data: action.payload,
        loading: 'succeeded',
      };
    case 'LOADING':
      return {
        loading: 'loading',
      };
    default:
      throw new Error();
  }
};

export const useHistory = (): [state: historyState, dispatch: React.Dispatch<ActionType>] => {
  const [state, dispatch] = useReducer(historyReducer, {
    loading: 'idle',
  });

  return [state, dispatch];
};
