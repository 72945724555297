// import { Col, Row } from 'antd';
import { Button } from 'antd';
import { Divider } from 'antd';
import { Link } from 'react-scroll';
import { Link as FooterLink } from 'react-router-dom';
import Logo from '../../assets/logo.svg';
import './Footer.css';

function Footer() {
  const getTargetUrl = () => {
    if (process.env.REACT_APP_ENV === 'production') {
      return '/coming-soon';
    } else {
      return process.env.REACT_APP_WEB_APP_URL;
    }
  };

  const currentYear = new Date().getFullYear();

  return (
    <footer className="Footer">
      <div className="container">
        <div className="top-footer">
          <div className="">
            <img src={Logo} alt="logo" />
            <div className="footer-links">
              <FooterLink className="text" to={'#'}>
                <Link activeClass="active" to="about" spy={true} smooth={true} offset={0} duration={500}>
                  About
                </Link>
              </FooterLink>
              <FooterLink className="text" to={'#'}>
                <Link activeClass="active" to="testimonials" spy={true} smooth={true} offset={0} duration={500}>
                  Testimonials
                </Link>
              </FooterLink>
              <FooterLink className="text" to={'#'}>
                <Link activeClass="active" to="contact" spy={true} smooth={true} offset={0} duration={500}>
                  Contact Us
                </Link>
              </FooterLink>
              <FooterLink className="text" to={'#'}>
                <Link activeClass="active" to="faqs" spy={true} smooth={true} offset={0} duration={500}>
                  FAQs
                </Link>
              </FooterLink>
            </div>
          </div>
          <Button onClick={() => window.open(getTargetUrl(), '_blank')} type="primary">
            Sign Up to Web App
          </Button>
        </div>
        <Divider />
        <small className="bottom-footer">
          <p>&copy; {currentYear} Status Trust. All rights reserved.</p>
          <div>
            <p>Privacy</p>
            <p>Terms of Use</p>
          </div>
        </small>
      </div>
    </footer>
  );
}

export default Footer;
