import './ServiceDetail.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js';
import { CalendarOutlined, InfoCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { History, STBarChart, StatusCardHeader } from '../components/ui';
import { Link, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { STState, useSTDispatch } from '../stStore';
import { useSelector } from 'react-redux';
import { toggleFavorite } from '../features/favoritesSlice';
import { useService, useChart, useHistory } from '../hooks';
import { PublicServiceAPI } from '../apis';
import { toStartUpperCase } from '../utils';
import { ReactComponent as STArrowSvg } from '../assets/icons/arrow.svg';
import Icon from '@ant-design/icons/lib/components/Icon';
import { Flex, Spin, message } from 'antd';
import { AxiosError } from 'axios';

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend);

export function ServiceDetail() {
  const favorites = useSelector((state: STState) => state.favorites);
  const params = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [service, dispatchService] = useService();
  const [chart, dispatchChart] = useChart();
  const [history, dispatchHistory] = useHistory();
  const dispatch = useSTDispatch();

  useEffect(() => {
    const handleError = (error: any) => {
      if (error instanceof AxiosError) {
        messageApi.error(error.message);
      } else {
        messageApi.error('Something went wrong');
      }
    };

    const fetchService = async (serviceId: string) => {
      dispatchService({ type: 'LOADING' });
      try {
        const response = await PublicServiceAPI.getById(serviceId);
        dispatchService({ type: 'LOADED', payload: response });
      } catch (err) {
        dispatchService({ type: 'ERROR', error: new Error('HTTP error') });
        handleError(err);
      }
    };

    const fetchChart = async (serviceId: string) => {
      dispatchChart({ type: 'LOADING' });
      try {
        const response = await PublicServiceAPI.getChart(serviceId);
        dispatchChart({ type: 'LOADED', payload: response });
      } catch (err) {
        dispatchChart({ type: 'ERROR', error: new Error('HTTP error') });
        handleError(err);
      }
    };

    const fetchHistory = async (serviceId: string) => {
      dispatchHistory({ type: 'LOADING' });
      try {
        const response = await PublicServiceAPI.getHistory(serviceId);
        dispatchHistory({ type: 'LOADED', payload: response });
      } catch (err) {
        dispatchHistory({ type: 'ERROR', error: new Error('HTTP error') });
        handleError(err);
      }
    };

    if (!params.id) {
      return;
    }

    fetchService(params.id);
    fetchChart(params.id);
    fetchHistory(params.id);
  }, [params.id, dispatchService, dispatchChart, dispatchHistory, messageApi]);

  return (
    <>
      {contextHolder}
      <div className="ServiceDetail plr-page pt-lg mt-lg">
        <Link to="..">
          <Icon component={STArrowSvg} className="icon-grey icon-md" />
          <span className="text-like-link">Back</span>
        </Link>
        <div className="card plr-md pb-md pt-md mt-lg">
          <StatusCardHeader
            service={service.data}
            isFavorite={favorites.values.some(f => f.id === service.data.serviceId)}
            onClickFavorite={() =>
              dispatch(
                toggleFavorite({
                  id: service.data.serviceId,
                  category: service.data.category,
                })
              )
            }
          />
          {chart.data.data.length > 0 && (
            <div className="timeframe">
              <CalendarOutlined style={{ fontSize: 14, color: '#cac7d5', paddingRight: 11 }} />
              {toStartUpperCase(dayjs(chart.data.data[0].date).format('MMM DD'))} -{' '}
              {toStartUpperCase(dayjs(chart.data.data[chart.data.data.length - 1].date).format('MMM DD'))}
            </div>
          )}
          <div className="StatusModal__chart_wrapper">
            <div className="chart-label-wrapper flex-row">
              <div></div>
              <div className="flex-row wrap-on-mobile">
                <div className="flex-row">
                  <div className="md-box box -green"></div>Operational
                </div>
                <div className="flex-row">
                  <div className="md-box box -yellow"></div>Incident
                </div>
                <div className="flex-row">
                  <div className="md-box box -red"></div>Downtime
                </div>
              </div>
            </div>
            {chart.loading === 'loading' && (
              <Flex justify="center">
                <Spin size="large" />
              </Flex>
            )}
            <STBarChart
              data={chart.data.data.map(d => {
                return {
                  date: d.date,
                  success: d.totalSuccessCount,
                  incident: d.totalIncidentCount,
                  total: d.totalSuccessCount,
                };
              })}
            />
          </div>
          <div className="footer flex-row wrap-on-mobile grey-text">
            <div className="flex-row">
              <div className="info-icon-wrapper">
                <InfoCircleOutlined style={{ fontSize: '16px', color: '#cac7d5' }} />
              </div>
              <span>
                <b className="accent-text">StatusTrust</b> checks for brand status every 60 minutes
                <br></br>
                (24 times a day).
              </span>
            </div>
            <div>
              <div>
                <div>
                  <div className="box sm-box -green"></div>
                  <b className="black-text">Operational</b> for more than 12 checks.
                </div>
                <div>
                  <div className="box sm-box -yellow"></div>
                  <b className="black-text">Incident</b> for more than 12 checks.
                </div>
                <div>
                  <div className="box sm-box -red"></div>
                  <b className="black-text">Downtime</b> for more than 12 checks.
                </div>
              </div>
            </div>
          </div>
        </div>
        {history.data && <History data={history.data} />}
      </div>
    </>
  );
}
