import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PublicCategoryAPI } from '../apis';
import { PublicCategory } from '../models';

export interface CategoriesState {
  values: PublicCategory[];
  loading: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState: CategoriesState = {
  values: [] as PublicCategory[],
  loading: 'idle',
};

export const fetchCategories = createAsyncThunk('categories/fetchAll', async () => {
  const response: PublicCategory[] = await PublicCategoryAPI.getCategories();
  return response;
});

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.values = action.payload;
      state.loading = 'succeeded';
    });
    builder.addCase(fetchCategories.pending, state => {
      state.values = [];
      state.loading = 'loading';
    });
    builder.addCase(fetchCategories.rejected, state => {
      state.values = [];
      state.loading = 'failed';
    });
  },
});

export default categoriesSlice.reducer;

export const selectCategories = (state: CategoriesState) => {
  return {
    values: state.values,
    loading: state.loading,
  };
};
