import React from 'react';
import type { CollapseProps } from 'antd';
import { Collapse } from 'antd';
import './Accordian.css';
import { SlArrowUp } from 'react-icons/sl';

const items: CollapseProps['items'] = [
  {
    key: '1',
    label: 'Will my users have access to a public dashboard where they can see the status of our services?',
    children: (
      <p>
        Yes, they will. Statustrust provides a public dashboard for your users depending on your subscription plan. When
        you sign up to use Statustrust, you must provide an account username (e.g. Stryde). This username is used to
        create a subdomain (e.g. https://stryde.statustrust.io) for your public dashboard which will be accessible to
        your users.
      </p>
    ),
  },
  {
    key: '2',
    label: 'Can I use my custom domain for my public dashboard?',
    children: (
      <p>
        Yes, you can. This also depends on your subscription plan. Statustrust will provide you with a CNAME record
        which you can configure in your domain provider management portal.
      </p>
    ),
  },
  {
    key: '3',
    label: 'How often will my services be checked for downtime?',
    children: (
      <p>
        Statustrust currently checks the availability of services every 1 hour. However, this can be changed to be more
        frequently checked, depending on our customer requirements or subscription plans.
      </p>
    ),
  },
  {
    key: '4',
    label: 'What kind of notifications are supported?',
    children: (
      <p>
        Statustrust currently supports Email, Slack and webhook notifications. We are also working on integration into
        Jira service management and Freshservice. This will enable you to receive alerts as tickets when services are
        down and assign them to the responsible team.
      </p>
    ),
  },
  {
    key: '5',
    label: 'Do you also support uptime notifications?',
    children: <p>Yes, we do. Statustrust notifies our customers when a service recovers from a downtime.</p>,
  },
];

const Accordian: React.FC = () => (
  <div className="Accordian">
    <h1 className="accordian-header">Frequently Asked Questions (FAQ) – Unveiling the Details of Status Trust</h1>
    <Collapse
      size="large"
      accordion
      ghost
      defaultActiveKey={['1']}
      items={items}
      expandIconPosition={'end'}
      expandIcon={({ isActive }) => (
        <SlArrowUp style={{ transform: isActive ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 0.2s' }} />
      )}
    />
  </div>
);

export default Accordian;
